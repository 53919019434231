<template>
<div>
  <div class="landing-page">
    <table>
      <tr>
        <table>
          <tr>
            <td style="max-width:59.3382%">
              <img :src="`https://static.idealagent.com/web/title/${imagePath}/Ideal-Title-Landing_01.jpg`" width="100%" alt="Ideal Title">
            </td>
            <td style="max-width:40.6617%;cursor:pointer;">
              <a href="tel:18884358485">
                <img :src="`https://static.idealagent.com/web/title/${imagePath}/Ideal-Title-Landing_02.jpg`" width="100%" alt="1-888-IDL-TITL | 1-888-435-8485">
              </a>
            </td>
          </tr>
        </table>
      </tr>
      <tr>
        <td>
          <img class="modal-trigger" @click="toggleModal" :src="`https://static.idealagent.com/web/title/${imagePath}/Ideal-Title-Landing_03.jpg`" width="100%" alt="Experience the future of Real Estate Closings">
        </td>
      </tr>
      <tr>
        <td>
          <img :src="`https://static.idealagent.com/web/title/${imagePath}/Ideal-Title-Landing_04.jpg`" width="100%" alt="Why Choose Ideal Title? Service at a value, Technology &amp; communication, safe and secure, low fee guarantee">
        </td>
      </tr>
      <tr>
        <td>
          <img :src="`https://static.idealagent.com/web/title/${imagePath}/Ideal-Title-Landing_05.jpg`" width="100%" alt="sky">
        </td>
      </tr>
      <tr>
        <td>
          <img :src="`https://static.idealagent.com/web/title/${imagePath}/Ideal-Title-Landing_06.jpg`" width="100%" alt="homes">
        </td>
      </tr>
      <tr>
        <table>
          <tr>
            <td style="max-width:59.338235%">
              <a href="tel:18884358485">
                <img :src="`https://static.idealagent.com/web/title/${imagePath}/Ideal-Title-Landing_07.jpg`" width="100%" alt="Call us, we can help! 1-888-IDL-TITLE | 1-888-435-8485">
              </a>
            </td>
            <td style="max-width:40.661764%;cursor:pointer;">
              <img :src="`https://static.idealagent.com/web/title/${imagePath}/Ideal-Title-Landing_08.jpg`" width="100%" alt="Ideal Title logo">
            </td>
          </tr>
        </table>
      </tr>
    </table>
    <div class="locations-wrap">
      <div class="locations">
        <ul>
          <li><span>Irvine, CA</span></li>
          <li class="strong">Ideal Escrow of California Inc<span><a href="https://www.google.com/maps/place/16755+Von+Karman+Ave,+Irvine,+CA+92606" target="_blank">map</a></span></li>
          <li><a href="https://www.google.com/maps/place/16755+Von+Karman+Ave,+Irvine,+CA+92606" target="_blank">16755 Von Karman Ave, Suite 200</a></li>
          <li><a href="https://www.google.com/maps/place/16755+Von+Karman+Ave,+Irvine,+CA+92606" target="_blank">Irvine, CA 92606</a></li>
        </ul>
        <ul>
          <li><span>Safety Harbor, FL</span></li>
          <li class="strong">Ideal Title Agency, LLC <span><a href="https://www.google.com/maps/place/100+Main+St,+Safety+Harbor,+FL+34695" target="_blank">map</a></span></li>
          <li><a href="https://www.google.com/maps/place/100+Main+St,+Safety+Harbor,+FL+34695" target="_blank">100 Main St., Suite 303</a></li>
          <li><a href="https://www.google.com/maps/place/100+Main+St,+Safety+Harbor,+FL+34695" target="_blank">Safety Harbor, FL 34695</a></li>
        </ul>
        <ul>
          <li><span>Charlotte, NC</span></li>
          <li class="strong">Ideal Title Agency, LLC <span><a href="https://www.google.com/maps/place/129+W+Trade+St,+Charlotte,+NC+28202//" target="_blank">map</a></span></li>
          <li><a href="https://www.google.com/maps/place/129+W+Trade+St,+Charlotte,+NC+28202/" target="_blank">129 W Trade St., FLR 9</a></li>
          <li><a href="https://www.google.com/maps/place/129+W+Trade+St,+Charlotte,+NC+28202/" target="_blank">Charlotte, NC 28202</a></li>
        </ul>
      </div>
      <div class="locations">
        <ul>
          <li><span>Coraopolis, PA</span></li>
          <li class="strong">Ideal Title Agency, LLC <span><a href="https://www.google.com/maps/place/400+Rouser+Rd,+Coraopolis,+PA+15108/" target="_blank">map</a></span></li>
          <li><a href="https://www.google.com/maps/place/400+Rouser+Rd,+Coraopolis,+PA+15108/" target="_blank">400 Rouser Road, Bldg 2, Suite 101</a></li>
          <li><a href="https://www.google.com/maps/place/400+Rouser+Rd,+Coraopolis,+PA+15108/" target="_blank">Coraopolis, PA 15108</a></li>
        </ul>
        <ul>
          <li><span>Frisco, TX</span></li>
          <li class="strong">Ideal Title of Texas LLC <span><a href="https://www.google.com/maps/place/2591+Dallas+Parkway,+Frisco,+TX+75034" target="_blank">map</a></span></li>
          <li><a href="https://www.google.com/maps/place/2591+Dallas+Parkway,+Frisco,+TX+75034" target="_blank">2591 Dallas Parkway, Suite 300</a></li>
          <li><a href="https://www.google.com/maps/place/2591+Dallas+Parkway,+Frisco,+TX+75034" target="_blank">Frisco, Texas 75034</a></li>
        </ul>
        <ul>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
        </ul>
      </div>
    </div>
    <div class="disclaimer-wrap">
      <p>Ideal Title Agency, LLC  is not engaged in rendering legal, accounting, or other professional advice or services. If legal advice or services or other expert assistance are required, 
      the services of a competent professional should be sought. <a href="/PrivacyNotice.pdf" target="_blank">Click here to view our privacy policy</a>.</p>
    </div>
  </div>
  <div class="modal" :class="[{'show-modal': openModal}]">
    <div class="modal-content-wrap">
      <span class="close-button" @click="toggleModal">×</span>
      <div class="modal-content">      
        <div class="title-text">
          <h2>Ideal Title Locations</h2>
          <p>
            Please select your state below:
          </p>
        </div>
        <div class="state-name">
          {{ stateNameHover }}
        </div>
        <div class="select-state-map">
          <div>
            <usa-map v-on:map-clicked="onMapClick" @state-name-hover="getStateNameHover"
                    @state-name-off="clearStateName"></usa-map>
          </div>
        </div>
        <div class="select-state-text">
          <ul class="list-buttons" :class="`list-buttons-${imagePath}`">
            <li v-for="x in stateNames" :key="x">
              <span class="button" @click="onMapClick({ title: x })">{{ x }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>  
</div>
</template>

<script>
import UsaMap from './components/UsaMap.vue'

export default {
  name: 'IdealTitleMap',
  data: function () {
    return {
      stateNameHover: '',
      imagePath: 'lg',
      openModal: false,
      stateNames: [
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming'
      ]
    }
  },
  mounted() {    
    this.imagePath = window.screen.width > 576 ? 'lg' : 'sm'
  },
  components: {
    UsaMap
  },
  props: {
    msg: String
  },
  methods: {
    onMapClick: function (attr) {
      let link = 'https://53.billerdirectexpress.com/ebpp/IdealTitle/'

      switch (attr.title) {
        case 'Arizona':
          link = 'https://53.billerdirectexpress.com/ebpp/IdealTitleAZ/'
          break
        case 'Ohio':
          link = 'https://53.billerdirectexpress.com/ebpp/IdealTitleOH/'
          break
        case 'West Virginia':
          link = 'https://53.billerdirectexpress.com/ebpp/IdealTitleWV/'
          break
        case 'Virginia':
          link = 'https://53.billerdirectexpress.com/ebpp/IdealTitleVA/'
          break
        case 'Missouri':
          link = 'https://53.billerdirectexpress.com/ebpp/IdealTitleVA/'
          break
      }
        window.location = link      
    },
    toggleModal: function () {
      this.openModal = !this.openModal
    },
    getStateNameHover: function (val) {
      this.stateNameHover = val.title
    },
    clearStateName: function (val) {
      this.stateNameHover = val
    }
  }
}
</script>
<style>
  * {
    box-sizing: border-box;
  }

  table td { font-size: 0px; }

  table {
    border-collapse: collapse;
  }

  th, td, body {
    padding: 0;
  }

  html {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    color: #3e3e3e;
    background-color: aliceblue;
  }

  .landing-page {
    max-width:1350px;
    margin:0 auto;
    background-color: #fff;
  }

  .title-text {
    text-align: center;
  }

  h2 {
    font-size: 2em;
    margin: 0;
    padding: 0;
  }

  .flex-grid {
    display: flex;
    margin: 0;
  }

  .list-buttons-lg {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }

  .list-buttons-sm {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }

  .list-buttons li {
    margin: 4px 0;
  }

  .list-buttons li span {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }

  .chevron::before {
    color: #000;
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    left: 0.15em;
    position: relative;
    top: 0.15em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.45em;
  }

  .chevron.bottom:before {
    top: 0;
    transform: rotate(135deg);
  }

  .col {
    flex: 1;
    padding:0;
  }

  .disclaimer-wrap {
    padding: 30px 0 100px 0;
    text-align: left;
    line-height: 1.3rem;
    background: #fff;
  }

  .title-text p {
    margin: 3px;
  }

  .select-state-map {
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
  }

  .select-dropdown {
    position: absolute;
    width: 40px;
    height: 40px;
    border-left: solid 1px #000;
    right: 0;
    top: -2px;
    padding: 2px;
  }

  .select-dropdown .chevron {
    position: relative;
    top: 8px;
    left: -3px;
  }

  .state-name {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #0051bd;
    position: relative;
    top: 15px;
    font-size: 1.5em;
    border-radius: 2px;
    max-width: 300px;
    padding: 3px 1px 4px 1px;
    margin: 0 auto;
    height: 30px;
    text-align: center;
  }

  .locations-wrap {
    min-height: 200px;
  }

  .locations {
    max-width: 1142px;
    position:relative;
    left: 3px;
    margin: 0 auto;
    margin-top:30px;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  .strong {
    font-weight:bold;
    padding-left: 9px;
  }

  .locations > ul {
    flex: 1 1 30%;
    height: 100px;
    margin-left: 0;
    padding-left: 0;
  }

  .locations > ul li {
    list-style: none;
    margin-bottom: 6px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .locations > ul li:first-child span {
    background-color: #4292d1;
    display: inline-block;
    width:90%;
    color:#fff;
    padding: 6px 3px;
    margin-left: -4px;
    margin-bottom: 5px;
    padding-left: 13px;
    border-radius: 15px;
  }

  .locations > ul:last-child li span {
    width:100%;
  }

  .locations > ul li a {
    text-decoration: none;
    color: #555;
    margin-left: 7px;
    cursor: pointer;
  }

  .strong span a {
    color: rgb(17 158 217 / 67%) !important;
    text-decoration: underline !important;
    font-size: 14px;
  }

  .modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  }

  .modal-content-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: aliceblue;
    width: 24rem;
    border-radius: 0.5rem;
    max-width: 700px;
    width: 90%;
    padding: 10px;
  }

  .modal-content {
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .close-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: #cde2f4;
  }

  .close-button:hover {
    background-color: #57abf5;
    color:#fff;
  }

  .show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  }

  @media (max-width: 576px) {  
    .select-state-map {
      display: none;
    }
    .title-text {
      padding-top: 20px;
    }
  }

  
  @media (min-width: 576px) {  
    .select-state-text{
      margin-top:-60px;
    }
  }

  @media (max-height: 700px) {  
    .select-state-map {
      display: none;
    }
    .select-state-text {
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 1024px) {
    .locations {
      flex-direction: column;
    }
    .locations ul {
      width: 320px;
    }
    .locations > ul li span {
      width:100% !important;
    }
  }
	</style>
